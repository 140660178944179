import logger from 'redux-logger';
import { Provider } from 'react-redux';
import { withRouter } from 'react-router';
import ReduxToastr from 'react-redux-toastr';
import createSagaMiddleware from 'redux-saga';
import { ThemeProvider } from 'styled-components';
import React, { Fragment, Component } from 'react';
import { createLogicMiddleware } from 'redux-logic';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ConnectedRouter, routerMiddleware } from 'react-router-redux';
import { compose, applyMiddleware, createStore as createReduxStore } from 'redux';
import { ThemeProvider as MUIThemeProvider, THEME_ID } from '@mui/material/styles';
// root
import mainSaga from './saga';
import arrLogic from './logic';
import reducers from './reducer';
import routes, { RouteWithSubRoutes } from './routes-config';
// theme
import { Theme, MUITheme } from './theme';
// helpers
import { history } from './helpers/route';
// ui
import { RootWrapper } from './ui';
import GlobalStyle from './ui/global';
//////////////////////////////////////////////////

const initialState = {};

let devToolsExtensionComposable = null;

if (
  typeof window !== 'undefined' &&
  process.env.NODE_ENV !== 'production'
) {
  if (window.devToolsExtension) {
    devToolsExtensionComposable = window.devToolsExtension();
  }
}

export function createStore(mainSaga: Function, ...additionalMiddlewares: Array) {
  const logicMiddleware = createLogicMiddleware(arrLogic);
  const sagaMiddleware = createSagaMiddleware();

  if (
    typeof window !== 'undefined' &&
    process.env.NODE_ENV === 'development'
  ) {
    additionalMiddlewares.push(logger);
  }

  const middleWares = [logicMiddleware, sagaMiddleware, ...additionalMiddlewares];

  const composable = [applyMiddleware(...middleWares)];

  if (devToolsExtensionComposable) {
    composable.push(devToolsExtensionComposable);
  }

  const store = createReduxStore(reducers, initialState, compose(...composable));

  if (module.hot) {
    module.hot.accept('./reducer', () => {
      store.replaceReducer(reducers);
    });
  }

  const sagas = [
    mainSaga,
  ];

  sagas.forEach((saga: Function) => sagaMiddleware.run(saga));

  return store;
}

const store = createStore(mainSaga, routerMiddleware(history));

const RootContainer = withRouter((props: Object) => (
  <div>
    {props.routes.map((route: Object, i: number) => <RouteWithSubRoutes key={i} {...route} />)}
  </div>
));

class AppRouter extends Component {
  render() {
    return (
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ConnectedRouter store={store} history={history}>
            <ThemeProvider theme={Theme}>
              <MUIThemeProvider theme={{ [THEME_ID]: MUITheme }}>
                <Fragment>
                  <RootWrapper>
                    <RootContainer routes={routes} />
                    <ReduxToastr
                      timeOut={10000}
                      progressBar={true}
                      newestOnTop={false}
                      position='top-left'
                      transitionIn='fadeIn'
                      transitionOut='fadeOut'
                      preventDuplicates={true}
                    />
                  </RootWrapper>
                  <GlobalStyle />
                </Fragment>
              </MUIThemeProvider>
            </ThemeProvider>
          </ConnectedRouter>
        </LocalizationProvider>
      </Provider>
    );
  }
}

export default AppRouter;
